<template>
  <app-page-layout :title="performanceEvaluationResult.name" hide-icon @close="leavePage">
    <template #content>
      <v-row>
        <v-col v-if="loading" cols="12">
          <v-progress-linear indeterminate color="grey lighten-2" height="80" />
        </v-col>
        <v-col v-else cols="12">
          <v-list three-line>
            <v-list-item class="px-0">
              <v-list-item-avatar
                :color="performanceEvaluationResult.evaluatee.profile.imageUrl ? 'white' : 'primary'"
                class="mr-3"
                size="80"
              >
                <v-img
                  style="border:2px solid #333"
                  v-if="performanceEvaluationResult.evaluatee.profile.imageUrl"
                  :src="performanceEvaluationResult.evaluatee.profile.imageUrl"
                  :lazy-src="performanceEvaluationResult.evaluatee.profile.imageUrl"
                >
                  <template v-slot:placeholder>
                    <v-row class="justify-center align-center fill-height">
                      <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                  </template>
                </v-img>
                <span class="white--text font-weight-bold" v-else>
                  {{ performanceEvaluationResult.evaluatee.firstName.substring(0, 1).toUpperCase() }}
                  {{ performanceEvaluationResult.evaluatee.lastName.substring(0, 1).toUpperCase() }}
                </span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ performanceEvaluationResult.evaluatee.firstName }}
                  {{ performanceEvaluationResult.evaluatee.lastName }}
                </v-list-item-title>
                <v-list-item-subtitle class="body-2 font-weight-bold">
                  {{
                    performanceEvaluationResult.evaluatee.employment.branch &&
                      performanceEvaluationResult.evaluatee.employment.branch.name
                  }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="body-1">
                  {{
                    performanceEvaluationResult.evaluatee.employment.jobInfo &&
                      performanceEvaluationResult.evaluatee.employment.jobInfo.name
                  }}
                </v-list-item-subtitle>
                <v-list-item-title>{{
                  performanceEvaluationResult.totalAverageScore &&
                    performanceEvaluationResult.totalAverageScore.toFixed(2)
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-subtitle class="body-2 mb-1">
                  <b>{{ $t("hr.performance.results.period") }}</b> :
                  {{ performanceEvaluationResult.expirationBeginDate | formattedDate }} -
                  {{ performanceEvaluationResult.expirationEndDate | formattedDate }}
                </v-list-item-subtitle>

                <v-list-item-subtitle class="body-2 mb-1" v-if="performanceEvaluationResult.scoreSettings">
                  <b>{{ $t("hr.performance.results.scoring") }}</b> :
                  {{ performanceEvaluationResult.scoreSettings && performanceEvaluationResult.scoreSettings.minScore }}
                  -
                  {{ performanceEvaluationResult.scoreSettings && performanceEvaluationResult.scoreSettings.maxScore }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="body-2">
                  <b>{{ $t("hr.performance.results.endDate") }}</b> :
                  {{ performanceEvaluationResult.validationBeginDate | formattedDate }}
                </v-list-item-subtitle>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12">
          <v-expansion-panels v-model="expanedPanels" flat multiple>
            <v-expansion-panel :value="0">
              <v-expansion-panel-header class="py-0">
                <v-row class="align-center">
                  <v-col cols="1">
                    <v-icon class="kpiColor">mdi-star</v-icon>
                  </v-col>
                  <v-col cols="9">
                    <div class="title kpiColor">
                      {{ $t("hr.performance.type.KPI") }} (%{{ performanceEvaluationResult.kpiWeight }})
                    </div>
                    <div class="caption kpiColor">KPI</div>
                  </v-col>
                  <v-col cols="2">
                    {{
                      performanceEvaluationResult.averageKpiScore &&
                        performanceEvaluationResult.averageKpiScore.toFixed(2)
                    }}
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card
                  flat
                  v-for="(row, index) in performanceEvaluationResult.kpiCategories"
                  class="mb-3"
                  :key="index"
                >
                  <v-card-text class="pb-0 pt-0">
                    <v-row class="px-3 pa-0 grey lighten-4 title">
                      <v-col cols="1">
                        <v-icon :size="16" class="ml-1">mdi-stop</v-icon>
                      </v-col>
                      <v-col cols="11">
                        {{ row.name }}
                      </v-col>
                    </v-row>
                    <v-row class="align-center justify-center px-4 mb-2" v-for="(item, key) in row.items" :key="key">
                      <v-col cols="1">
                        <v-icon :size="12" class="ml-1">mdi-record</v-icon>
                      </v-col>
                      <v-col cols="6">
                        {{ item.name }}
                      </v-col>
                      <v-col cols="5" class="text-right">
                        {{ getScoreDescription(item.score) }} / {{ item.score }} ({{
                          item.actualScore && item.actualScore.toFixed(2)
                        }})
                      </v-col>
                      <v-col cols="12" class="pl-6 py-0" v-if="item.description">
                        <v-icon size="16" class="ml-1 mr-2" color="teal">mdi-message-reply-text</v-icon>
                        {{ item.description }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel :value="1">
              <v-expansion-panel-header class="py-0">
                <v-row class="align-center">
                  <v-col cols="1">
                    <v-icon class="competenceColor">mdi-star</v-icon>
                  </v-col>
                  <v-col cols="9">
                    <div class="title competenceColor">
                      {{ $t("hr.performance.type.COMPETENCE") }}
                    </div>
                    <div class="caption competenceColor">COMPETENCE</div>
                  </v-col>

                  <v-col cols="2" class="text-center pl-3">
                    {{
                      performanceEvaluationResult.averageCompetenceScore &&
                        performanceEvaluationResult.averageCompetenceScore.toFixed(2)
                    }}
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card
                  flat
                  v-for="(row, index) in performanceEvaluationResult.competenceCategories"
                  class="mb-3"
                  :key="index"
                >
                  <v-card-text class="pb-0 pt-0">
                    <v-row class="px-3 py-0 grey lighten-4 title">
                      <v-col cols="1">
                        <v-icon :size="16" class="ml-1">mdi-stop</v-icon>
                      </v-col>
                      <v-col cols="11">
                        {{ row.name }}
                      </v-col>
                    </v-row>
                    <v-row class="align-center justify-center px-4 mb-2" v-for="(item, key) in row.items" :key="key">
                      <v-col cols="1">
                        <v-icon :size="12" class="ml-1">mdi-record</v-icon>
                      </v-col>
                      <v-col cols="6">
                        {{ item.name }}
                      </v-col>
                      <v-col cols="5" class="text-right">
                        {{ getScoreDescription(item.score) }} / {{ item.score }} ({{
                          item.actualScore && item.actualScore.toFixed(2)
                        }})
                      </v-col>
                      <v-col cols="12" class="pl-6 py-0" v-if="item.description">
                        <v-icon size="16" class="ml-1 mr-2" color="teal">mdi-message-reply-text</v-icon>
                        {{ item.description }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </template>
    <template #footer>
      <app-page-footer />
    </template>
  </app-page-layout>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-01-12 10:30:16
   * @modify date 2021-01-12 10:30:16
   * @desc PerformanceResult
   */
  import { PERFORMANCE_RESULT_EVALUATOR_BY_EVALUATION } from "./query";
  import {
    PerformanceEvaluationResult,
    PerformanceEvaluationResultCategory,
    PerformanceEvaluationResultCategoryItem
  } from "./model/PerformanceEvaluationResult";

  export default {
    name: "PerformanceResult",
    components: {
      AppPageFooter: () => import("@/layouts/app-page-footer")
    },
    props: {
      id: String,
      item: Object
    },
    data: vm => ({
      loading: false,
      performanceEvaluationResult: new PerformanceEvaluationResult(),
      scoreInfo: false,
      expanedPanels: [0, 1]
    }),
    watch: {
      id: {
        handler(id) {
          this.fetchPerformanceEvaluationResult();
        },
        immediate: true
      }
    },
    methods: {
      leavePage() {
        this.$router.replace({ name: "performances" });
      },
      getScoreDescription(score) {
        let result = null;
        for (let i = 0; i < this.performanceEvaluationResult.scoreSettings.scoreSettingsDescriptions.length; i++) {
          let current = this.performanceEvaluationResult.scoreSettings.scoreSettingsDescriptions[i];
          if (current.fromScore <= score && current.toScore >= score) {
            result = current.description;
            break;
          }
        }
        return result;
      },
      fetchPerformanceEvaluationResult() {
        const options = {
          query: PERFORMANCE_RESULT_EVALUATOR_BY_EVALUATION,
          fetchPolicy: "no-cache",
          variables: {
            evaluator: this.$helpers.get(this.item, "evaluator.id"),
            evaluation: this.$helpers.get(this.item, "evaluation.id"),
            evaluatee: this.$helpers.get(this.item, "evaluatee.id")
          }
        };
        this.loading = true;
        this.$apollo
          .query(options)
          .then(({ data: { error, performanceEvaluationResultByEvaluatorAndEvaluationAndEvaluatee }, errors }) => {
            if (!error && !errors) {
              const performanceEvaluationResult = performanceEvaluationResultByEvaluatorAndEvaluationAndEvaluatee;
              this.performanceEvaluationResult.id = performanceEvaluationResult.id;
              this.performanceEvaluationResult.name = performanceEvaluationResult.evaluation.name;
              this.performanceEvaluationResult.expirationBeginDate =
                performanceEvaluationResult.evaluation.expirationBeginDate;
              this.performanceEvaluationResult.expirationEndDate =
                performanceEvaluationResult.evaluation.expirationEndDate;
              this.performanceEvaluationResult.validationBeginDate =
                performanceEvaluationResult.evaluation.validationBeginDate;
              this.performanceEvaluationResult.validationEndDate =
                performanceEvaluationResult.evaluation.validationEndDate;
              this.performanceEvaluationResult.evaluatee = performanceEvaluationResult.evaluator;
              this.performanceEvaluationResult.scoreSettings = performanceEvaluationResult.evaluation.scoreSettings;
              this.performanceEvaluationResult.competenceWeight =
                performanceEvaluationResult.evaluation.template.competenceWeight;
              this.performanceEvaluationResult.kpiWeight = performanceEvaluationResult.evaluation.template.kpiWeight;
              this.performanceEvaluationResult.averageKpiScore = performanceEvaluationResult.kpiTotalActualScore;
              this.performanceEvaluationResult.averageCompetenceScore =
                performanceEvaluationResult.competenceTotalActualScore;
              this.performanceEvaluationResult.totalAverageScore = performanceEvaluationResult.totalActualScore;
              performanceEvaluationResult.kpiCategories.forEach((item, key) => {
                let category = new PerformanceEvaluationResultCategory();
                category.id = item.id;
                category.name = item.category.category.name;
                category.color = item.category.category.color;
                category.type = item.category.category.type;
                category.weight = item.totalActualScore;
                item.items.forEach((row, index) => {
                  let item = new PerformanceEvaluationResultCategoryItem();
                  item.id = row.id;
                  item.weight = row.item.weight;
                  item.name = row.item.item.name;
                  item.color = row.item.item.color;
                  item.score = row.score || null;
                  item.actualScore = row.actualScore || null;
                  item.description = row.item.item.description;
                  item.comment = row.item.item.comment;
                  category.items.push(item);
                });
                this.performanceEvaluationResult.kpiCategories.push(category);
              });
              performanceEvaluationResult.competenceCategories.forEach((item, key) => {
                let category = new PerformanceEvaluationResultCategory();
                category.id = item.id;
                category.name = item.category.category.name;
                category.color = item.category.category.color;
                category.type = item.category.category.type;
                category.weight = item.totalActualScore;
                item.items.forEach((row, index) => {
                  let item = new PerformanceEvaluationResultCategoryItem();
                  item.id = row.id;
                  item.weight = row.item.weight;
                  item.name = row.item.item.name;
                  item.color = row.item.item.color;
                  item.score = row.score || null;
                  item.actualScore = row.actualScore || null;
                  item.description = row.item.item.description;
                  item.comment = row.item.item.comment;
                  category.items.push(item);
                });
                this.performanceEvaluationResult.competenceCategories.push(category);
              });
            } else {
              this.leavePage();
            }
          })
          .catch(e => this.$helpers.showNotification(e.message))
          .finally(() => (this.loading = false));
      }
    }
  };
</script>

<style scoped lang="scss">
  .v-expansion-panel--active + .v-expansion-panel,
  .v-expansion-panel--active:not(:first-child) {
    margin-top: 0px !important;
  }
</style>
