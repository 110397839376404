export class PerformanceEvaluationResult {
  constructor() {
    this.id = null;
    this.name = null;
    this.score = null;
    this.totalAverageScore = null;
    this.averageKpiScore = null;
    this.averageCompetenceScore = null;
    this.expirationBeginDate = null;
    this.expirationEndDate = null;
    this.validationBeginDate = null;
    this.validationEndDate = null;
    this.scoreSettings = null;
    this.evaluatee = new PerformanceEvaluationResultEvaluatee();
    this.kpiCategories = [];
    this.competenceCategories = [];
  }
}

class PerformanceEvaluationResultDTO {
  constructor() {
    this.id = null;
    this.score = null;
    this.description = null;
  }
}

export class PerformanceEvaluationResultEvaluatee {
  constructor() {
    this.firstName = "";
    this.lastName = "";
    this.profile = { imageUrl: null };
    this.employment = { branch: { name: null }, jobInfo: { name: null } };
  }
}

export class PerformanceEvaluationResultScoreSettings {
  constructor() {
    this.minScore = null;
    this.maxScore = null;
    this.scoreSettingsDescriptions = { fromScore: null, toScore: null, description: null };
  }
}

export class PerformanceEvaluationResultCategory {
  constructor() {
    this.id = null;
    this.name = null;
    this.color = null;
    this.type = null;
    this.items = [];
  }
}

export class PerformanceEvaluationResultCategoryItem {
  constructor() {
    this.id = null;
    this.name = null;
    this.score = null;
    this.weight = null;
    this.description = null;
    this.comment = null;
  }
}

export default PerformanceEvaluationResult;
